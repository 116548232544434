import styled from 'styled-components';
import { theme } from 'styled-tools';

import mediaQuery from '../../../utils/mediaQuery';

const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  border: ${theme('components.modal.buttonBorder', '3')}px solid currentColor;
  padding: 7px 15px 12px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  margin-top: 15px;
  color: ${theme('components.modal.buttonColor')};
  border-radius: ${theme('components.modal.buttonRadius', '0')}px;
  display: block;
  width: 100%;

  svg,
  path {
    color: inherit;
  }

  &:hover,
  &:active {
    &,
    span {
      color: ${theme('components.modal.primary')};
    }
  }

  ${mediaQuery.min('sm')} {
    display: inline-block;
    width: 150px;

    &:not(:first-of-type) {
      margin-left: 15px;
    }

    &:not(:last-of-type) {
      margin-right: 15px;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }
  }
`;

export default Link;
