import { PropsWithChildren, useEffect } from 'react';
import { useBrowserSpecs } from '../../../utils/useBrowserSpecs';
import { useGlobalModal } from '../../Modals';

const AssertBrowser = ({ children }: PropsWithChildren<{}>) => {
  const { isInvalidBrowser, webgl } = useBrowserSpecs();
  const [, showModal] = useGlobalModal();

  const available = !isInvalidBrowser && !!webgl;

  useEffect(() => {
    if (!available) {
      showModal(1);
    }
  }, [available]);

  return <>{available && children}</>;
};

export default AssertBrowser;
