import { useParams } from 'react-router-dom';
import { useAppConfig } from '../../utils/useAppConfig';
import { useIsExpired } from '../../utils/useIsExpired';
import { GamePage } from '../Game';
import ExpiredPage from './pages/ExpiredPage';
import LandingPage from './pages/LandingPage';

const INVALID_HASHES = ['cpf', 'email'];

const Main = () => {
  const { hash } = useParams<{ hash: string }>();
  const { accessMode } = useAppConfig();

  const isExpired = useIsExpired();
  if (isExpired) {
    return <ExpiredPage />;
  }

  switch (accessMode) {
    case 'none':
      return <LandingPage />;
    case 'expired':
      return <ExpiredPage />;
    case 'cpf':
      return <GamePage hash="cpf" />;
    case 'email':
      return <GamePage hash="email" />;
  }

  const isValidHash = hash && !INVALID_HASHES.includes(hash);
  if (!isValidHash) {
    return <LandingPage />;
  }

  return <GamePage hash={hash} />;
};

export default Main;
