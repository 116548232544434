import { useRouteMatch } from 'react-router';
import { useEvent } from 'react-use';

import { useBrowserSpecs } from '../../../utils/useBrowserSpecs';

const BlockSwipe = () => {
  const { platform } = useBrowserSpecs();
  const enabled = platform?.type !== 'desktop';
  const match = useRouteMatch();

  const onAvoidSwipe = () => {
    const { params: { hash } = {} } = match;
    if (!enabled) return;

    setTimeout(() => {
      if (hash) window.location.hash = hash;
    });
  };

  const onPopState = () => {
    const { params: { hash } = {} } = match;
    if (!enabled) return;
    if (!window.oldHash || window.oldHash !== hash) window.oldHash = hash;

    window.history.go(1);
  };

  const onHashChange = () => {
    const { params: { hash } = {} } = match;
    if (!enabled) return;

    if (window.oldHash && window.oldHash !== hash) {
      window.location.hash = window.oldHash;
    }
  };

  useEvent('load', onAvoidSwipe);
  useEvent('hashchange', onHashChange);
  useEvent('popstate', onPopState);
  useEvent('beforeunload', onAvoidSwipe);

  return null;
};

export default BlockSwipe;
