import { useMemo } from 'react';

import { useAppConfig } from '../../../utils/useAppConfig';
import { useLocale } from '../../../utils/useLocale';

const DEFAULT_LOCALE = 'pt-br';

export function useGameUrl(hash: string) {
  const locale = useLocale();
  const appConfig = useAppConfig();

  const urlFromConfig = appConfig.game.url;

  return useMemo(() => {
    const parsedUrl = new URL(urlFromConfig, window.location.href);

    parsedUrl.searchParams.set('l', locale ?? DEFAULT_LOCALE);
    parsedUrl.searchParams.set('t', String(Date.now()));

    // Game expects hash to starts with /
    const absoluteHash = hash.startsWith('/') ? hash : `/${hash}`;
    parsedUrl.hash = absoluteHash;

    return parsedUrl.toString();
  }, [hash, locale, urlFromConfig]);
}
