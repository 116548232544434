import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppConfig } from './useAppConfig';
import config from '../data/config';

export const useLocale = () => {
  const { i18n } = useTranslation();
  const appConfig = useAppConfig();

  const locale = useMemo(() => {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const paramLocale = params?.get('l');
    return paramLocale?.replace(/\//gi, '');
  }, [window]);

  useEffect(() => {
    let language = appConfig.forceLanguage ?? locale;

    if (!language) {
      language = config.defaultLng;
    }

    language = language?.replace(/(\-.*)/, '');

    if (!config.supportedLngs?.includes(language)) {
      language = config.defaultLng;
    }

    i18n.changeLanguage(language);
  }, [i18n, locale]);

  return locale;
};
