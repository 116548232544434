import { useAppConfig } from '../../../utils/useAppConfig';
import ChromeButton from './ChromeButton';
import FirefoxButton from './FirefoxButton';

const SuggestedBrowsers = () => {
  const appConfig = useAppConfig();
  const suggested = appConfig.browsers?.suggested ?? {};
  return (
    <>
      {suggested.chrome && <ChromeButton />}
      {suggested.firefox && <FirefoxButton />}
    </>
  );
};

export default SuggestedBrowsers;
