import InvalidBrowser from './InvalidBrowser';
import WebGlDisabled from './WebGlDisabled';
import WebGlNotSupported from './WebGlNotSupported';

import keys from '../modalKeys';

export default {
  [keys.INVALID_BROWSER]: InvalidBrowser,
  [keys.WEBGL_DISABLED]: WebGlDisabled,
  [keys.WEBGL_NOT_SUPPORTED]: WebGlNotSupported,
  DEFAULT: () => null,
};
