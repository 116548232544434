import merge from 'lodash/merge';
import { useMemo } from 'react';
import { defaultAppConfig, devAppConfig } from '../data/appConfig';
import { AppConfig } from '../types';
import { useQueryConfig } from './useQueryConfig';

export function useAppConfig(): AppConfig {
  const { data: config = undefined } = useQueryConfig();
  return useMemo(() => {
    return merge({}, defaultAppConfig, config, devAppConfig);
  }, [config]);
}
