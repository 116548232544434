import styled from 'styled-components';

import { useImage } from '../../utils/useImage';

const Link = styled.a.attrs({
  target: '_BLANK',
  rel: 'noopener noreferrer',
})`
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 20px;
  }
  &:not(:first-of-type) {
    margin-left: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
`;

const Logo = ({ src, imgTitle, imgAlt, ...props }) => {
  const $src = useImage(src);
  return (
    <Link {...props}>
      <Image imgTitle={imgTitle} imgAlt={imgAlt} src={$src} />
    </Link>
  );
};

export default Logo;
