import { encodeToParams } from './encodeToParams';

export const useShareableUrl = ({ platform, url }) => {
  switch (platform) {
    default:
    case 'whatsapp':
    case 'wpp':
      return `https://api.whatsapp.com/send?${encodeToParams({ text: url })}`;
    case 'facebook':
    case 'fb':
      return `https://www.facebook.com/sharer/sharer.php?${encodeToParams({
        u: url,
      })}`;
    case 'twitter':
    case 'twtr':
      return `https://twitter.com/share?${encodeToParams({ url })}`;
  }
};
