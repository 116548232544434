export default {
  primary100: '#ffffff',
  primary200: '#cccccc',
  primary300: '#425563',
  primary400: '#1c1c1c',
  primary500: '#000000',

  blue400: '#005eef',

  chrome100: '#1DA462',
  chrome200: '#DD5144',
  chrome300: '#FFCD46',
  chrome400: '#4C8BF5',

  firefox100: '#E66000',
  firefox200: '#FF9500',
  firefox300: '#FFCB00',
  firefox400: '#00539F',
  firefox500: '#0095DD',
  firefox600: '#331E54',
};
