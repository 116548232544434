import bowser from 'bowser';
import merge from 'lodash/merge';
import { checkWebGl } from './checkWebGl';
import { useAppConfig } from './useAppConfig';

const browser = bowser.getParser(window.navigator.userAgent);

const browserSpecs = browser.getResult();

const isIos = browser.isBrowser('iOS');

const isSafari = browser.isBrowser('safari') || browser.isBrowser('Safari');

const anyWindow: any = window;
const isIphone =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !anyWindow.MSStream;

const webgl = checkWebGl();

export const useBrowserSpecs = () => {
  const config = useAppConfig();

  const isInvalidBrowser = !browser.satisfies(config.browsers?.required);

  return merge(browserSpecs, {
    isIos,
    isSafari,
    isIphone,
    isInvalidBrowser,
    webgl,
  });
};
