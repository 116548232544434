import { useEvent, useTimeoutFn } from 'react-use';
import { prop, theme } from 'styled-tools';

import { normalize } from 'polished';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import mediaQuery from '../utils/mediaQuery';
import { useAppConfig } from '../utils/useAppConfig';
import Fonts from './Fonts';
import Head from './Head';
import ThemeOverride from './ThemeOverride';

const GlobalStyle = createGlobalStyle`

:root {
  --vh: ${prop('vh')}px;
}

html {

    ${normalize()}

    overflow: hidden;
    font-family: 'CustomerFont', 'Open Sans', sans-serif;
    font-size: 15px;

    ::-moz-selection {
      background: ${theme('components.selection.background')};
      color: ${theme('components.selection.color')};
    }
    ::selection {
      background: ${theme('components.selection.background')};
      color: ${theme('components.selection.color')};
    }
  }

  body {
    margin: 0;
    background: ${theme('components.core.primary', 'white')};
    color: ${theme('components.core.secondary', 'black')};

    ${mediaQuery.landscape()} {
      height: var(--vh);
      overflow: auto;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    height: var(--vh);
  }
`;

const AppContext = ({ children }) => {
  const [height, setHeight] = React.useState(
    document.documentElement.clientHeight
  );

  const [, scrollToTop, cancelScroll] = useTimeoutFn(async () => {
    document.body.scrollTo(0, 0);
    setHeight(document.documentElement.clientHeight);
  }, 1000);

  const onResize = () => {
    setHeight(document.documentElement.clientHeight);
  };

  const onOrientationChange = async () => {
    cancelScroll();
    scrollToTop();
  };

  const { theme } = useAppConfig();

  useEvent('resize', onResize);
  useEvent('orientationchange', onOrientationChange);

  return (
    <ThemeOverride theme={theme.overrides}>
      <Head />
      <Fonts />
      <GlobalStyle vh={height} />
      {children}
    </ThemeOverride>
  );
};

export default AppContext;
