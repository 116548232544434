import React from 'react';

import { encodeToParams } from './encodeToParams';

const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = function () {
      reject();
    };
    img.onload = function () {
      resolve(img);
    };
    img.src = url;
  });
};

export const useImage = (url, params) => {
  const [src, setSrc] = React.useState();
  React.useEffect(() => {
    const req = async () => {
      try {
        const $params = encodeToParams(params);
        const $image = await preloadImage(`${url}?${$params}`);
        setSrc($image.src);
      } catch (e) {}
    };
    req();
  }, [url, params]);
  return src;
};
