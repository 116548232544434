import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { FaChrome } from 'react-icons/fa';

import Icon from './Icon';
import LinkButton from './LinkButton';

const ChromeIcon = styled(Icon).attrs({
  icon: <FaChrome />,
})`
  color: ${theme('components.chrome.primary')};
`;

const Link = styled(LinkButton)`
  color: ${theme('components.chrome.primary')};
  &:hover,
  &:active {
    &,
    span {
      border-color: ${theme('components.chrome.primary')};
      background-color: ${theme('components.chrome.primary')};
    }
  }
`;

const ChromeButton = () => {
  return (
    <Link href="https://www.google.com/intl/pt-BR_ALL/chrome/">
      <ChromeIcon />
      Chrome
    </Link>
  );
};

export default ChromeButton;
