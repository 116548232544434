import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import PageTemplate from '../../../shared/PageTemplate/PageTemplate';

const Box = styled.p`
  background: ${theme('components.expired.background', 'transparent')};
  border: ${theme('components.expired.outlineWidth', '10')}px
    ${theme('components.expired.outline', 'transparent')} solid;
  color: ${theme('components.expired.color', 'black')};
  text-align: center;
  margin-top: 15px;

  border-radius: 5px;
  padding: 1em;
`;

const ExpiredPage = () => {
  const { t } = useTranslation();
  return (
    <PageTemplate>
      <Box>{t('expiredMessage')}</Box>
    </PageTemplate>
  );
};

export default ExpiredPage;
