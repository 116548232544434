import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useAppConfig } from '../../utils/useAppConfig';
import Logo from './Logo';
import WidthLimit from './WidthLimit';

const Wrapper = styled.footer`
  width: 100%;
  margin-top: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${theme('components.footer.background', 'transparent')};
`;

const Container = styled(WidthLimit)``;

const StyledLogoPartner = styled(Logo)`
  max-width: 140px;
`;

const Footer = () => {
  const { t } = useTranslation();

  const { theme } = useAppConfig();

  const imgAlt = t('imgAlt');

  return (
    <Wrapper>
      <Container>
        <StyledLogoPartner
          src={theme.images.logoPartner}
          imgAlt={imgAlt}
          imgTitle={imgAlt}
        />
      </Container>
    </Wrapper>
  );
};

export default Footer;
