import { useBrowserSpecs } from './useBrowserSpecs';
import modalKeys from '../features/Modals/modalKeys';

export const useModal = () => {
  const { isInvalidBrowser, webgl } = useBrowserSpecs();
  if (isInvalidBrowser) {
    return modalKeys.INVALID_BROWSER;
  } else if (webgl === 0) {
    return modalKeys.WEBGL_DISABLED;
  } else if (webgl === -1) {
    return modalKeys.WEBGL_NOT_SUPPORTED;
  }
};
