import { createGlobalStyle, useTheme } from 'styled-components';

import { Helmet } from 'react-helmet';

const CommonFont = () => {
  return (
    <Helmet>
      <style>
        {`
          html {
            font-family: 'Montserrat', sans-serif;
          }
        `}
      </style>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap"
      />
    </Helmet>
  );
};

const CustomFont = createGlobalStyle`
    @font-face {
        font-family: 'CustomerFont';
        src: url('./static/fonts/CustomerFont.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'CustomerFont';
        src: url('./static/fonts/CustomerFontBold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'CustomerFont';
        src: url('./static/fonts/CustomerFontItalic.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: 'CustomerFont';
        src: url('./static/fonts/CustomerFontMedium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    html {
        font-family: 'CustomerFont', 'Montserrat', sans-serif;
    }
`;

export default () => {
  const { components } = useTheme();
  return (
    <>
      <CommonFont />
      {components?.core?.customerFont ? <CustomFont /> : null}
    </>
  );
};
