import { debugBanner } from '../pushstart';
import localConfig from './config.local';
import merge from 'lodash/merge';

const SUPPORTED_LOCALES = ['pt', 'en', 'es'];
const isLocaleSupported = SUPPORTED_LOCALES.some((locale) =>
  window?.navigator?.language?.startsWith(locale)
);

const { version } = require('../../package.json');

const DEBUG = process.env.NODE_ENV === 'development';

const buildSpecs = {
  version: process.env.REACT_APP_VERSION || version,
  build: process.env.REACT_APP_BUILD_NUMBER,
  hash: process.env.REACT_APP_COMMIT_HASH,
  env: process.env.REACT_APP_ENV || process.env.NODE_ENV,
};

debugBanner(buildSpecs);

const config = {
  version,

  api: '/api',
  env: process.env.REACT_APP_ENV,

  defaultLng: isLocaleSupported
    ? window?.navigator.language
    : SUPPORTED_LOCALES[0],

  supportedLngs: SUPPORTED_LOCALES,

  sentry: {
    enabled: !DEBUG && !!process.env.REACT_APP_SENTRY,
    dsn: process.env.REACT_APP_SENTRY,
  },

  ga: {
    enabled: !DEBUG && !!process.env.REACT_APP_GA,
    id: process.env.REACT_APP_GA,
  },

  queryCache: {
    defaultConfig: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

if (DEBUG) {
  merge(config, localConfig);
}

export default config;
