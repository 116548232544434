import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { FaFirefox } from 'react-icons/fa';

import Icon from './Icon';
import LinkButton from './LinkButton';

const FirefoxIcon = styled(Icon).attrs({
  icon: <FaFirefox />,
})`
  color: ${theme('components.firefox.primary')};
`;

const Link = styled(LinkButton)`
  color: ${theme('components.firefox.primary')};
  &:hover,
  &:active {
    &,
    span {
      border-color: ${theme('components.firefox.primary')};
      background-color: ${theme('components.firefox.primary')};
    }
  }
`;

const FirefoxButton = () => {
  return (
    <Link href="https://www.mozilla.org/pt-BR/firefox/new/">
      <FirefoxIcon />
      Firefox
    </Link>
  );
};

export default FirefoxButton;
