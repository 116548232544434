import { merge } from 'lodash';
import { AppConfig } from '../types';

export const defaultAppConfig: AppConfig = {
  title: 'PushStart',

  theme: {
    overrides: {
      core: {
        primary: '#ffffff',
      },
    },
    images: {
      logoPushStart: './static/themes/default/logo-pushstart.png',
      logoProgram: './static/themes/default/logo-program.png',
      logoPartner: './static/themes/default/logo-partner.png',
    },
  },

  accessMode: 'hash',

  game: {
    url: 'game/',
    aspectRatio: '9x16',
  },

  browsers: {
    // Generated usin standard query
    // See: https://browserslist.dev/?q=PiAwLjUlLCBsYXN0IDIgdmVyc2lvbnMsIEZpcmVmb3ggRVNSLCBub3QgZGVhZCwgbm90IElFIDktMTE%3D
    // See: https://pushstart.atlassian.net/wiki/spaces/KPROD/pages/4369973337/Requisitos+-+M+nimos+e+Recomendados
    required: {
      chrome: '>101',
      firefox: '>101',
      edge: '>102',

      opera: '>85',

      samsung_internet: '>16.0',
      safari: '>15.4',
    },
    suggested: {
      chrome: true,
      firefox: true,
    },
  },
};

export const devAppConfig: Partial<AppConfig> = {};
if (process.env.NODE_ENV === 'development') {
  merge(devAppConfig, {
    // Add development-only config here
    accessMode: 'hash',
    game: {
      url: 'https://pushstart.com.br/game/',
    },
  });
}
