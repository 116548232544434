import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Header from './Header';

import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useAppConfig } from '../../utils/useAppConfig';
import Logo from './Logo';
import WidthLimit from './WidthLimit';

const Wrapper = styled.main`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  display: flex;
  flex: 1;
  background-color: ${theme('components.main.background', 'transparent')};
`;

const Container = styled(WidthLimit)``;

const StyledLogo = styled(Logo).attrs({
  forwardedAs: 'div',
})`
  max-width: 450px;
`;

const PageTemplate = ({ children }) => {
  const { ready } = useTranslation();

  const { t } = useTranslation();
  const { theme } = useAppConfig();

  const imgAlt = t('imgAlt');

  if (!ready) return null;

  return (
    <>
      <Header />
      <Wrapper>
        <Container>
          <StyledLogo
            src={theme.images.logoProgram}
            imgAlt={imgAlt}
            imgTitle={imgAlt}
          />
          {children}
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
};

export default PageTemplate;
