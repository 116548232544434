export const SOURCE_TYPE = 'PushStart_FrameMessage.Call';
export const SOURCE_SENDER = 'game';
export const SOURCE_RECEIBER = SOURCE_SENDER;
export const PLATFORM_TYPE = 'PushStart_FrameMessage.Call';
export const PLATFORM_SENDER = 'site';

export const DEFAULT_MESSAGE = {
  type: PLATFORM_TYPE,
  sender: PLATFORM_SENDER,
  receiver: SOURCE_RECEIBER,
};

export const FUNCTION_NAMES = {
  SHARE: 'share',
  SCORE: 'score',
  ANALYITCS: 'analytics',
  FACEBOOK: 'requestFacebook',
};

export const VALID_SOURCES = [SOURCE_TYPE, PLATFORM_TYPE];
