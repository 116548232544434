import './setup/setupSentry';
import './setup/setupAnalytics';
import './setup/setupI18n';
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import '@babel/polyfill';
import 'react-responsive-modal/styles.css';

import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import config from './data/config';
import reportWebVitals from './reportWebVitals';

const queryCache = new QueryCache(config.queryCache);

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <HashRouter>
        <App />
      </HashRouter>
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
