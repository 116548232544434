import styled from 'styled-components';
import { rem } from 'polished';
import { theme } from 'styled-tools';

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  font-size: ${rem('16px')};
  color: ${theme('components.modal.secondary')};

  max-width: 620px;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  h3:first-of-type {
    margin-top: 0;
  }

  p,
  h3 {
    width: 100%;
  }

  p:first-of-type {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  p {
    line-height: 1.5em;
    a {
      color: ${theme('components.modal.anchor')};
    }
  }

  p + p {
    margin-top: 0em;
  }
`;
