import styled from 'styled-components';
import { theme } from 'styled-tools';

const Wrapper = styled.span`
  margin-right: 0.5em;
  margin-left: -0.5em;
  font-size: 1.5em;
  position: relative;
  top: 0.2em;

  color: ${theme('components.modal.secondary', 'black')};

  svg,
  path {
    fill: currentColor;
    color: inherit;
  }
`;

const Icon = ({ icon = () => null, ...props }) => {
  return <Wrapper {...props}>{icon}</Wrapper>;
};

export default styled(Icon)``;
