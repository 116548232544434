import Axios from 'axios';
import { captureException } from '@sentry/browser';
import config from '../data/config';

const api = Axios.create({
  baseURL: config.api,
  timeout: 60000,
  headers: {},
});

function onResponse(response) {
  return response.data || response.body || response.text;
}

async function onError(response) {
  const data =
    response.data || response.response || response.body || response.text;
  const error = data || { code: -1 };
  captureException(error);
  throw error;
}

export default {
  get: (endpoint) => {
    return api.get(endpoint).then(onResponse).catch(onError);
  },

  post: (endpoint, data = {}) => {
    return api.post(endpoint, data).then(onResponse).catch(onError);
  },

  put: (endpoint, data = {}) => {
    return api.put(endpoint, data).then(onResponse).catch(onError);
  },

  delete: (endpoint) => {
    return api.delete(endpoint).then(onResponse).catch(onError);
  },

  local: (endpoint) => {
    const Request = Axios.create({
      timeout: 60000,
      headers: {},
    });
    return Request.get(endpoint).then(onResponse).catch(onError);
  },

  data: (endpoint) => {
    const Request = Axios.create({
      timeout: 60000,
      headers: {},
    });
    return Request.get(`/content/data${endpoint}`)
      .then(onResponse)
      .catch(onError);
  },

  ping: (endpoint) => {
    return api.get(endpoint).then(onResponse).catch(onError);
  },
};
