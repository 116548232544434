import api from '../services/_api';
import { useQuery } from 'react-query';

const CACHE_KEY = 'config';

export const useQueryConfig = () => {
  return useQuery(CACHE_KEY, async () => {
    try {
      return await api.Common.config();
    } catch (e) {
      // Fail silently
      return {};
    }
  }, {
    staleTime: 999999999
  });
};
