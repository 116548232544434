export const WEBGL_STATUS = {
  ENABLED: 1,
  DISABLED: 0,
  NOT_SUPPORTED: -1,
};

export const WEBGL_LABELS = {
  [WEBGL_STATUS.ENABLED]: 'ENABLED',
  [WEBGL_STATUS.DISABLED]: 'DISABLED',
  [WEBGL_STATUS.NOT_SUPPORTED]: 'NOT_SUPPORTED',
};

const WEBGL_FLAGS = [
  'webgl2',
  'webgl',
  'experimental-webgl',
  'moz-webgl',
  'webkit-3d',
];

export const checkWebGl = () => {
  if (!!window.WebGLRenderingContext) {
    const canvas = document.createElement('canvas');
    for (const flag of WEBGL_FLAGS) {
      try {
        const context = canvas.getContext(flag);
        if (context && typeof context.getParameter == 'function') {
          return WEBGL_STATUS.ENABLED;
        }
        /* eslint-disable-next-line */
      } catch (e) {}
    }
    return WEBGL_STATUS.DISABLED;
  }
  return WEBGL_STATUS.NOT_SUPPORTED;
};
