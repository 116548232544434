import * as Sentry from '@sentry/browser';

import { WEBGL_LABELS, checkWebGl } from '../utils/checkWebGl';

import { Integrations } from '@sentry/tracing';
import config from '../data/config';

Sentry.init({
  enabled: config.sentry.enabled,
  dsn: config.sentry.dsn,
  release: config.version,
  environment: config.env,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [/Illegal invocation/i],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

Sentry.setUser({
  id: window.location.hash.replace(/^#\//, ''),
});

Sentry.configureScope(function (scope) {
  scope.setTag('webgl', WEBGL_LABELS[checkWebGl()]);
  scope.setTag('hash', window.location.hash.replace(/^#\//, ''));
});
