const min = (size) => {
  return (props) => `@media (min-width: ${props.theme?.breakpoints[size]}px)`;
};

const portrait = () => {
  return `@media (orientation: portrait)`;
};

const landscape = () => {
  return `@media (orientation: landscape)`;
};

export default {
  min,
  portrait,
  landscape,
};
