import styled from 'styled-components';

export default styled.button.attrs({
  id: 'facebook-button',
})`
  display: none;
  height: 0;
  width: 0;
  position: fixed;
  left: -100vw;
  top: -100vw;
`;
