import palette from './palette';
import breakpoints from './breakpoints';

export default {
  core: {
    primary: palette.primary100,
    secondary: palette.primary500,
    maxWidth: breakpoints.md,
    customerFont: null,
  },

  header: {},

  main: {},

  footer: {},

  expired: {
    background: palette.primary100,
    color: palette.primary500,
    outline: palette.primary300,
    outlineWidth: 5,
  },

  iframe: {
    background: palette.primary100,
    outline: palette.primary300,
  },

  selection: {
    background: 'red',
    color: palette.primary300,
  },

  firefox: {
    primary: palette.firefox100,
  },

  chrome: {
    primary: palette.chrome400,
  },

  modal: {
    primary: palette.primary100,
    secondary: palette.primary400,
    anchor: palette.blue100,
    buttonColor: palette.primary300,
    buttonRadius: 15,
    buttonBorder: 3,
  },
};
