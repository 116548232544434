import React from 'react';
import { createGlobalState } from 'react-use';

import templates from './templates';

import { useModal } from '../../utils/useModal';

export const useGlobalModal = createGlobalState(0);

const Modal = () => {
  const modalType = useModal();
  const [openned, toggleModal] = useGlobalModal();

  const Component = templates[modalType] || templates.DEFAULT;

  return <Component open={openned} onClose={() => toggleModal(0)} center />;
};

export default Modal;
