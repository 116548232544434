import styled from 'styled-components';
import { theme } from 'styled-tools';

export default styled.div`
  max-width: ${theme('components.core.maxWidth')}px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
