import { useAppConfig } from '../../../utils/useAppConfig';
import Container from '../layouts/Container';
import SuggestedBrowsers from '../layouts/SuggestedBrowsers';
import Wrapper from '../layouts/Wrapper';

const WebGlNotSupported = ({ ...props }) => {
  const appConfig = useAppConfig();
  return (
    <Container {...props}>
      <Wrapper>
        <h3>Este navegador não é suportado!</h3>
        <p>
          Para continuar, utilize a versão mais recente de um dos navegadores
          abaixo:
        </p>
        {!!appConfig.support?.url ? (
          <p>
            Caso continue tendo problemas, acesse o suporte em{' '}
            <a
              href={appConfig.support?.url}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              {appConfig.support?.label || appConfig.support?.url}
            </a>
          </p>
        ) : null}
        <SuggestedBrowsers />
      </Wrapper>
    </Container>
  );
};

export default WebGlNotSupported;
