import { useLockBodyScroll, useOrientation } from 'react-use';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import aspectRatios from './_aspectRatios';

import { PropsWithChildren } from 'react';
import mediaQuery from '../../../utils/mediaQuery';
import { useAppConfig } from '../../../utils/useAppConfig';

const Wrapper = styled.main`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type StyledGameContainerProps = {
  $aspectRatio: {
    width: number;
    height: number;
  };
};

const GamePortraitContainer = styled.div<StyledGameContainerProps>`
  height: var(--vh);
  width: calc(var(--vh) * ${prop('$aspectRatio.width')});
  ${mediaQuery.portrait()} {
    height: calc(var(--vh) * ${prop('$aspectRatio.height')});
  }
`;

const GameLandscapeContainer = styled.div<StyledGameContainerProps>`
  --game-height: var(--vh);

  height: var(--game-height);
  width: calc(var(--game-height) * ${prop('$aspectRatio.width')});

  ${mediaQuery.portrait()} {
    height: calc(100vw * 0.56);
  }

  ${mediaQuery.min('lg')} {
    --game-height: calc(var(--vh) - 75px);
    height: var(--game-height);
    width: calc(var(--game-height) * ${prop('$aspectRatio.width')});
  }
`;

type GameContainerProps = PropsWithChildren<{}>;

const GameContainer = ({ children }: GameContainerProps) => {
  const orientation = useOrientation();
  const appConfig = useAppConfig();
  const aspectRatio = aspectRatios[appConfig.game?.aspectRatio];

  const isPortrait = aspectRatio?.orientation === 'portrait';
  const GameFrameContainer = isPortrait
    ? GamePortraitContainer
    : GameLandscapeContainer;

  useLockBodyScroll(!orientation?.type?.includes('landscape'));
  return (
    <Wrapper>
      <GameFrameContainer $aspectRatio={aspectRatio}>
        {children}
      </GameFrameContainer>
    </Wrapper>
  );
};

export default GameContainer;
