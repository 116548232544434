import { Route, Switch } from 'react-router-dom';

import AppContext from './core/AppContext';
import { Main } from './features/Main';
import Modal from './features/Modals';
import { useQueryConfig } from './utils/useQueryConfig';

const App = () => {
  const { isLoading } = useQueryConfig();

  if (isLoading) {
    return null;
  }

  return (
    <AppContext>
      <Switch>
        <Route exact path="/:hash*" component={Main} />
      </Switch>
      <Modal />
    </AppContext>
  );
};

export default App;
