import api from '../../../services/_api';

import { useShareableUrl } from '../../../utils/useShareableUrl';
import { openUrl } from '../../../utils/openUrl';
import { sendEvent } from '../../../utils/analytics';

export const share = async ([platform, score]) => {
  const { url, text, hashtags } = await api.GAME.share({ score });
  const shareableUrl = useShareableUrl({
    platform,
    url,
    text,
    hashtags,
  });
  sendEvent({
    category: 'Social Share',
    action: platform,
    value: score,
  });
  openUrl(shareableUrl, undefined, undefined, () => {
    window.top.location = shareableUrl;
  });
};

export const score = async ([scored]) => {
  // const { url, text, hashtags } = await api.GAME.score({ score: scored });
  await api.GAME.score({ score: scored });
  sendEvent({
    category: 'Score',
    action: 'Register score',
    value: scored,
  });
};

export const analytics = async ([category, action, value]) => {
  sendEvent({
    category: category || 'Game',
    action: action,
    value: value || 0,
  });
};

export const requestFacebook = async () => {
  sendEvent({
    category: 'Game',
    action: 'Facebook',
    value: 1,
  });
  const fbButton = document.getElementById('facebook-button');
  if (fbButton) fbButton.click();
};
