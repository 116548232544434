import FacebookButton from '../../../shared/FacebookButton';
import AssertBrowser from '../components/AssertBrowser';
import BlockSwipe from '../components/BlockSwipe';
import GameContainer from '../components/GameContainer';
import GameFrame from '../components/GameFrame';
import { useGameUrl } from '../hooks/useGameUrl';

export type GamePageProps = {
  hash: string;
};

function GamePage({ hash }: GamePageProps) {
  const url = useGameUrl(hash);
  return (
    <AssertBrowser>
      <BlockSwipe />
      <GameContainer>
        <GameFrame url={url} />
      </GameContainer>
      <FacebookButton />
    </AssertBrowser>
  );
}

export default GamePage;
