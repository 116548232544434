import { useTranslation } from 'react-i18next';
import Container from '../layouts/Container';
import SuggestedBrowsers from '../layouts/SuggestedBrowsers';
import Wrapper from '../layouts/Wrapper';

const InvalidBrowser = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Container {...props}>
      <Wrapper>
        <h3>{t('browserSupport.title')}</h3>
        <p>{t('browserSupport.description')}</p>
        <SuggestedBrowsers />
      </Wrapper>
    </Container>
  );
};

export default InvalidBrowser;
