import { encodeToParams } from './encodeToParams';

export const openUrl = (
  url,
  target = '_blank',
  params = {},
  onSuccess = () => {},
  onBlocked = () => {}
) => {
  const popup = window.open(url, target, encodeToParams(params));
  try {
    popup.focus();
    onSuccess();
  } catch (e) {
    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      onBlocked();
    }
  }
};
