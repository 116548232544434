import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useAppConfig } from '../../utils/useAppConfig';
import Logo from './Logo';
import WidthLimit from './WidthLimit';

const Wrapper = styled.header`
  width: 100%;
  margin-bottom: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${theme('components.header.background', 'transparent')};
`;

const Container = styled(WidthLimit)``;

const StyledLogo = styled(Logo)`
  max-width: 140px;
`;

const Header = () => {
  const { t } = useTranslation();

  const { theme } = useAppConfig();

  return (
    <Wrapper>
      <Container>
        <StyledLogo
          src={theme.images.logoPushStart}
          alt={t('imgAlt')}
          title={t('imgAlt')}
        />
      </Container>
    </Wrapper>
  );
};

export default Header;
