import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import Modal from 'react-responsive-modal';

import { FaTimes } from 'react-icons/fa';

import Icon from './Icon';

const Close = styled(Icon).attrs({
  icon: FaTimes,
})``;

export default styled(Modal).attrs((props) => ({
  styles: {
    modal: {
      background: props.theme.components.modal.primary,
      padding: `${rem('36px')} ${rem('34px')}`,
    },
    closeButton: {
      width: rem('22px'),
      height: rem('22px'),
      cursor: 'pointer',
    },
  },
  closeIconSvgPath: <Close />,
}))``;
