import api from '../services/_api';
import { useAppConfig } from './useAppConfig';
import { useQuery } from 'react-query';

const CACHE_KEY = 'time';

export const useIsExpired = () => {
  const config = useAppConfig();
  const { data: res } = useQuery(CACHE_KEY, () => api.Common.time(), {
    enabled: !!config.expiresAt,
  });
  if (res) {
    return !!config.expiresAt && res?.epoch >= +config.expiresAt;
  }
  return undefined;
};
