import { useEvent } from 'react-use';
import { analytics, requestFacebook, score, share } from './_events';

import { captureException } from '@sentry/browser';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import mediaQuery from '../../../utils/mediaQuery';
import { useBrowserSpecs } from '../../../utils/useBrowserSpecs';
import { FUNCTION_NAMES } from './_postMessages';

const Frame = styled.iframe.attrs({
  scrolling: 'no',
  allowFullscreen: true,
  frameBorder: 0,
})`
  background-color: ${theme('components.iframe.background', 'grey')};
  outline: 8px solid ${theme('components.iframe.outline', 'black')};
  height: 100%;
  width: 100%;

  ${mediaQuery.landscape()} {
    ${ifProp('$isIframe', 'outline: none;')}
  }
`;

const GameFrame = ({ url }) => {
  const { isIphone } = useBrowserSpecs();
  const onReceiveMessage = async ({ data = {}, stopPropagation }) => {
    let { message } = data;
    try {
      if (typeof message === 'string' && message.length) {
        message = JSON.parse(message);
      }
      if (message) {
        if (typeof message.params === 'string') {
          message.params = JSON.parse(message.params);
        }
        switch (message.fnName) {
          case FUNCTION_NAMES.SHARE:
            share(message.params);
            break;
          case FUNCTION_NAMES.SCORE:
            score(message.params);
            break;
          case FUNCTION_NAMES.ANALYTICS:
            analytics(message.params);
            break;
          case FUNCTION_NAMES.FACEBOOK:
            requestFacebook();
            break;
          default:
            break;
        }
      }
      if (isFunction(stopPropagation)) stopPropagation();
    } catch (e) {
      captureException(e);
    }
  };

  const onLoad = () => {
    if (isIphone) {
      document
        .querySelector('iframe')
        .addEventListener('touchstart', () => false);
    }
  };

  useEvent('message', onReceiveMessage);

  return <Frame src={url} onLoad={onLoad} $isIframe={isIphone} />;
};

export default GameFrame;
