import React from 'react';
import { ThemeProvider } from 'styled-components';
import merge from 'lodash/merge';
import themes from '../data/themes';

const ThemeOverride = ({ children, theme }) => {
  const overridedTheme = React.useCallback((oldTheme = {}) => {
    return merge({}, themes, oldTheme, { components: theme });
  }, []);
  return (
    <ThemeProvider theme={overridedTheme}>
      <>{children}</>
    </ThemeProvider>
  );
};

export default ThemeOverride;
