import Helmet from 'react-helmet';
import { useAppConfig } from '../utils/useAppConfig';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Head = () => {
  const { t, i18n } = useTranslation();
  const { title } = useAppConfig();

  const htmlTitle = useMemo(() => {
    if (i18n.exists('htmlTitle') && t('htmlTitle')) {
      return t('htmlTitle');
    }
    return title;
  }, [i18n, title]);

  return (
    <Helmet
      title={htmlTitle}
      htmlAttributes={{
        lang: i18n.language,
      }}
    />
  );
};

export default Head;
